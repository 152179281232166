.container-contact {
 
max-width: 1400px;
margin: auto;
    .entete-contact {
        color: $white;
        width: 70%;
        text-align: center;
        margin: auto;
        padding-top: 150px;
        padding-bottom: 20px;

        p {
        padding-top: 10px;

            color: $gry;
        }
    }

    .content-contact {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin: auto;
        margin-bottom: 15%;

        form {
            width: 100%;
            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
span.yes{
    margin-top: 2%;
    color: green;
}
span.no{
    color: red;
}
            div {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                margin-bottom: 20px;
            }

        }


        input,
        textarea {
            padding: 3% 3% 3% 2%;
            font-size: 16px;
            border-width: 0px;
            border-color: transparent;
            background: rgba(255, 255, 255, 0.10);
            border-radius: 10px;
            box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.75);
            width: 100%;
            font-family: $font-1;
            color: $white;
        }

        textarea {
            min-height: 150px;
            resize: none;
        }

        input:focus {
            outline: none;
        }

        textarea:focus {
            outline: none;
        }

       
            input.send {
                cursor: pointer;
                font-size: 16px;
                text-decoration: none;
                border-radius: 24px;
                background: linear-gradient(145deg, #8800f7 0%, #46017e 100%);
                padding: 2% 4%;
                color: #ffffff;
                box-shadow: none;
                width: auto;
                margin-top: 3%;
            }
            input:hover {
font-weight: 700;                
        }

        
        button:disabled {
            border: 1px solid rgba(255, 255, 255, 0.171);
            background: rgba(255, 255, 255, 0.14);

        }
        button:active {
            position: relative;
            outline: none;
            top: 1px;
        }
       


    }
}


@media only screen and (min-width: 1200px) {
    .container-contact {
        .entete-contact {
            padding-bottom: 30px;
        }
        .content-contact {
        width: 90%;
        margin-bottom: 5%;

            form {
            width: 50%;

                div {
                    flex-direction: row;
                }
            }
        }
    }}

    @media only screen and (min-width: 992px) and (max-width : 1199px) {
        .container-contact {
            .entete-contact {
                padding-bottom: 30px;
            }
            .content-contact {
            width: 70%;
            margin-bottom: 5%;
    
                form {
                width: 70%;
    
                    div {
                        flex-direction: row;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 768px) and (max-width : 991px) {
        .container-contact {
            .entete-contact {
                padding-bottom: 30px;
            }
            .content-contact {
            width: 80%;
            margin-bottom: 5%;
    
                form {
                width: 100%;
    
                    div {
                        flex-direction: row;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 767px) {}

    @media only screen and (max-width: 576px) {}