.Pageprojet {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    color: $white;
    max-width: 1400px;
    margin: auto;

    .entete {
        margin-top: 50px;

        display: flex;
        width: 100%;
        padding: 5% 2%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;



        .navprojet {
            width: 20%;
            gap: 10px;
            display: flex;
            align-items: center;
            margin: 0 0 2% 0;

            img {
                height: 25px;
            }

            p {
                display: none;
            }
        }

        .prec {
            justify-content: flex-start;
        }

        .suiv {
            justify-content: flex-end;
        }

        .haut {
            display: flex;
            justify-content: space-around;
            width: 100%;
        }

        .titreprojet {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 90%;
            text-align: center;
        }

        .images {
            display: flex;
            flex-direction: column;
            width: 90%;
            align-items: center;
            justify-content: space-evenly;
            margin-top: 4%;
            gap: 20px;

            img {
                border-radius: 15px;
                width: 70%;
                display: flex;
                max-width: 500px;

                gap: 75px;
                flex-shrink: 0;
                flex-wrap: wrap;
                cursor: pointer;
            }
        }
    }

    .corps {
        width: 90%;
        margin: 4%;
        max-width: 1000px;
        gap: 40px;
        display: flex;
        flex-direction: column;

        h4 {
            margin-bottom: 2%;
        }

        h6 {
            margin-top: 5%;
            margin-bottom: 2%;
        }

        a {
            font-weight: bolder;
            color: $white;
        }

        a:hover {
            font-weight: bolder;
            color: $violetclair;
        }

        .outilutilise {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $white;

            .outilcadre {
                background-color: $violetfonce;
                padding: 1% 2%;
                margin-right: 2%;
                border-radius: 8px;
            }
        }

        .cta {
            a {
                color: $violetfonce;
                text-decoration: none;
            }
        }

        .image-gallery {
            margin: auto;
        }
    }

    .lightbox {
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        z-index: 100 !important;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.644);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-y: hidden;

        img {
            border-radius: 15px;
        }

        p {
            color: $white;
            position: fixed;
            top: 10px;
            left: 10px;
        }
    }

}

.no-scroll {
    overflow: hidden;
}


@media only screen and (min-width: 1200px) {
    .Pageprojet {
        .entete .images {
            flex-direction: row;

            img {
                width: 45%;
                max-width: 500px;

            }
        }

        .image-gallery-content .image-gallery-slide .image-gallery-image {
            height: 563px;
            max-height: 563px;
        }

        .image-gallery-thumbnail .image-gallery-thumbnail-image {
            height: 50px;
            max-height: 50px;
        }
    }

    .Pageprojet .entete .navprojet p {
        font-size: 0.8em;
        display: block;
    }
}

@media only screen and (min-width: 992px) and (max-width : 1199px) {
    .Pageprojet {

        .entete .images {
            flex-direction: row;

            img {
                width: 45%;
                max-width: 500px;
            }
        }

        .image-gallery-content .image-gallery-slide .image-gallery-image {
            height: 500px;
            max-height: 500px;
        }

        .image-gallery-thumbnail .image-gallery-thumbnail-image {
            height: 50px;
            max-height: 50px;
        }
    }


    .Pageprojet .entete .navprojet p {
        font-size: 0.6em;
        display: block;
    }
}

@media only screen and (min-width: 768px) and (max-width : 991px) {
    .Pageprojet {
        .entete .navprojet {
            width: 10%;
            justify-content: center;

            p {
                display: none;
            }
        }

        .image-gallery-content .image-gallery-slide .image-gallery-image {
            height: 400px;
            max-height: 400px;
        }

        .image-gallery-thumbnail .image-gallery-thumbnail-image {
            height: 50px;
            max-height: 50px;
        }
    }

}

@media only screen and (max-width: 767px) {
    .Pageprojet {
        .image-gallery-content .image-gallery-slide .image-gallery-image {
            height: 400px;
            max-height: 400px;
        }

        .image-gallery-thumbnail .image-gallery-thumbnail-image {
            height: 50px;
            max-height: 50px;
        }
    }
}

@media only screen and (max-width: 576px) {}