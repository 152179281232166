

.navbar {
  margin-top: 10px;
  height: 60px;
  position: fixed;
  width: 100%;
  z-index: 100;
display: flex;
align-items: center;
justify-content: center;
  backdrop-filter: blur(2px);

.fond-container{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 47px;
  background: rgba(255, 255, 255, 0.10);
  // backdrop-filter: blur(2px);
  max-width: 1300px;
  width: 100%;
  padding: 0 20px;
  animation: navAnim2 0.5s ease-in-out 0s 1 normal forwards;
  .container {
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
}
 
}
.fond-container.active {
background-color: $greyNav;
// max-width: none;
animation: navAnim 0.5s ease-in-out 0s 1 normal forwards;
}
#noanim{
  animation: none !important;
}
@keyframes navAnim {
	0% {
    background: rgba(255, 255, 255, 0.10);

	}
	100% {
    background-color: $greyNav;
	}
}
@keyframes navAnim2 {
	0% {
    background-color: $greyNav;
	}
	100% {
  background: rgba(255, 255, 255, 0.10);

	}
}
.logo img{
  height: 50px;
}

.menu-icon {
  display: none;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 40px;
  color: black;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: $white;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: $white;
  font-weight: 500;
  position: relative;
}
.nav-elements ul a:hover {
  font-weight: 600;
}
.nav-elements ul a.whitelink{
  color: $white;
}
.nav-elements ul a.blacklink{
  color: $black;
}
.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: $violetfonce;
}
.nav-elements ul a.active.boutonfondviolet::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
}



@media (max-width: 600px) {
  .navbar.false{
margin-top: 0;
.fond-container{
  border-radius:0px ;
  height: 100%;
}
  }
  .menu-icon {
    display: block;
    cursor: pointer;
    width: 35px;
  }
.navbar.active{
  height: 100vh;
  align-items: flex-start;
margin-top: 0;
.fond-container{
  border-radius:0px ;
}

}
  .nav-elements {
    position: absolute;
    right: 0;
    top: 55px;
    // background-color: $black;
    backdrop-filter: blur(10px);
    background: rgba(5, 5, 5, 0.664);
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
    border-radius: 26px 0 0 0 ;
  }

  .nav-elements.active {
    width: 100%;
    
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
    color: $black !important;

  }
  .nav-elements ul li:last-child a{
padding: 0;
background: transparent;

  }
  .nav-elements ul li{
    margin-right: 0px !important;
      }
      .nav-elements ul a.whitelink, .nav-elements ul a.blacklink, .nav-elements ul a.boutonfondviolet{
        color: $white !important;
        font-weight: 700;
      }
}