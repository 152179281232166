footer {
    height: 400px;
    background-color: #000000;
    color: #FFF;
    padding: 2%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    >div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        width: 90%;
        flex-wrap: wrap;
    }

    .end {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;

        hr {
            width: 100%;
            margin: 1% 0;
        }
    }

    img {
        width: 20px;
    }

    .logofooter {
        width: 15%;

        img {
            width: 60px;
        }
    }

    .navfooter {
        width: 60%;
        display: flex;
    
        align-content: center;
        justify-content: center;
        

        a {
            width: 45%;
            text-align: center;
            text-decoration: none;
            color: $white;
        }
    }

    .contactfooter {
        width: 15%;
        display: flex;
        align-items: baseline;
        justify-content: space-between;

    }


}


@media only screen and (min-width: 1200px) {
    footer img {
        width: 30px;
    }

    footer {
    height: 200px;
        
        .logofooter {
            width: 15%;
            
            img {
                width: 60px;
            }
        }

    }
}

@media only screen and (min-width: 992px) and (max-width : 1199px) {
    footer img {
        width: 20px;
    }

    footer{
        height: 200px;
        .logofooter {
            width: 10%;
    
            img {
                width: 60px;
            }
        }
    
        .navfooter {
            width: 60%;
        font-size: medium; }
        .contactfooter {
            width: 25%;
        }
    } 
}

@media only screen and (min-width: 768px) and (max-width : 991px) {
    footer img {
        width: 20px;
    }

    
    footer{
        height: 300px;
        >div {
            justify-content: center;
            gap: 30px;
         }
        .logofooter {
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 60px;
            }
        }
    
        .navfooter {
            width: 70%;
        font-size: medium; }
        .contactfooter {
            width: 40%;
            margin-bottom: 3%;
        }
      
    } 
}

@media only screen and (max-width: 767px) {
    .navfooter {
        width: 95%;
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        flex-shrink: 0;
        a {
            width: 45%;
            text-align: center;
        }
    }
    footer{
    height: 300px;

        >div {
            justify-content: center;
            gap: 20px;
         }
        .logofooter {
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 60px;
            }
        }
    
        .navfooter {
            width: 70%;
        font-size: medium; }
        .contactfooter {
            width: 50%;
            margin-bottom: 3%;
        }
       
    } 
}

@media only screen and (max-width: 576px) {
    footer {
        height: 300px;

        >div {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            margin: auto;
            width: 75%;
            gap: 20px;
        }

        #contactfooter {
            width: 50%;
        }
        .navfooter {
            width: 95%;
            display: flex;
            align-items: flex-start;
            align-content: flex-start;
            justify-content: center;
            flex-wrap: wrap;
            gap: 20px;
            flex-shrink: 0;
    margin-bottom: 5%;
            a {
                width: 45%;
                text-align: center;
            }
        }
    }
}