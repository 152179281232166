.page-projets {
    
    color: $white;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 100px;
    min-height: 100vh;
max-width: 1400px;
margin: auto;
    .entete {
        width: 85%;
        gap: 30px;
        display: flex;
        flex-direction: column;
    }

    .radio-container {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60px;
        padding: 10px;
        color: $white;
        width: 80%;

        input {
            transform: translateY(1px);
        }

        label {
            margin-left: 10px;
            cursor: pointer;
        }
    }

    input[type="text"] {
        padding: 10px;
        border-radius: 40px;
        font-size: 1.3rem;
        border: 3px solid $color-1;
        outline: none;
        transition: 0.4s;
        text-align: center;
        width: 400px;
        margin-bottom: 20px;

        &:focus {
            background: $violetfonce;
            border-radius: 4px;
            color: $white;
        }
    }

    button {
        background: none;
        color: $white;
        border: 1px solid #a0a0a0;
        padding: 10px 20px;
        border-radius: 24px;
        cursor: pointer;
        transition: 0.2s;
        font-family: $font-1;

        &:hover {
            background: linear-gradient(145deg, #8800f7 0%, #46017e 100%);
        }
    }

    .container-projets {
        justify-content: center;
        width: 85%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;

        .projet-link {
            margin-bottom: 10%;
            width: 23%;
            min-width: 270px;
            flex-wrap: wrap;
        }

        .projet {
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
            position: relative;
        }

        .imgprojet {
            align-self: stretch;
            border-radius: 8px;
            background: $violetfonce;
            height: auto;
            width: 100%;
           height: 200px;
            object-fit: cover;
        }

        .infosprojet.infos {
            visibility: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #00000096;
        }

        .infosprojet.infos.visible {
            visibility: visible;
        }

        .infosprojet.visible {
            visibility: hidden;
        }

        .infosprojet {
            color: $white;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
            background: rgb(0, 0, 0);
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 70%);

            .txtprojet {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                gap: 17px 15px;
                flex-wrap: wrap;
                padding: 4%;

                .compprojet {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    gap: 8px;
                    margin-top: 5%;
                }
            }

            .titreprojet {
                /* Corps */
                font-family: Montserrat;
                font-size: 95%;
            }
            .introprojet{
                font-size: 0.7em;
                
            }
            .boutonprojet {
                margin: 0;
            }
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    }
}



@media only screen and (min-width: 1200px) {
    .page-projets {
       
        .container-projets {
        justify-content: left;
        .projet-link {
            margin-bottom: 3%;}}
        
          
        }
            
}

@media only screen and (min-width: 992px) and (max-width : 1199px) {
    .page-projets {
        .container-projets {
        justify-content: left;
        .introprojet {
           font-size: 0.8em;
        }}}

}

@media only screen and (min-width: 768px) and (max-width : 991px) {
    .page-projets {
        .container-projets {
        justify-content: space-around;}}
        .page-projets .container-projets .infosprojet.infos.visible{
            visibility: hidden;
        }
        .page-projets .container-projets .infosprojet.visible{
            visibility: visible;
        }
}

@media only screen and (max-width: 767px) {
    .page-projets .container-projets .infosprojet.infos.visible{
        visibility: hidden;
    }
    .page-projets .container-projets .infosprojet.visible{
        visibility: visible;
    }
}

@media only screen and (max-width: 576px) {
    .page-projets .container-projets .infosprojet.infos.visible{
        visibility: hidden;
    }
    .page-projets .container-projets .infosprojet.visible{
        visibility: visible;
    }
}