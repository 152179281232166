@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat-Regular.ttf);
  // src: url(./assets/fonts/Inter-Regular.ttf);
  font-family: "font-2";
  // src: url(./assets/fonts/Montserrat-SemiBold.ttf);
  src: url(./assets/fonts/Inter-Black.ttf);

}

$font-1: "Montserrat", sans-serif;
$font-2: "font-2", sans-serif;

$color-1: #61dafb;
$color-2: salmon;
$color-3: #282c34;
$white: rgb(243, 243, 243);
$black: rgb(0, 0, 0);
$gry : rgb(175, 172, 172);
$violetclair: #CEC4FF;

$violetfonce: #8800f7;
$greyNav :#c5c5c5;
// $violetfonce: #0C003F;

@mixin verticalCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: $white;
  font-family: $font-1;
  // @include verticalCenter;
  font-size: 16pt;
  background-color: #000;
}


li {
  list-style-type: none;
}

body {
  font-size: 14pt;
}

p,
a {
  font-size: 1em;
}

h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 2.25em;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1.75em;
}

h5 {
  font-size: 1.5em;
}

h6 {
  font-size: 1.25em;
}

@media (max-width: 480px) {
  body {
    font-size: 12pt;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  body {
    font-size: 14pt;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  body {
    font-size: 16pt;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  body {
    font-size: 17pt;
  }
}