.bg-stage {
  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(59, 2, 104, 1) 50%, rgba(0, 0, 0, 1) 100%);
}
@media only screen and (min-width: 1200px) {

.images-stage {
  max-height: 400px;
  img {
    width: auto !important;
    max-height: 400px;
  }
}
}
.stage {
  .sous-partie {
    padding-left: 30px;
  }
  .visiweb{
    display: flex;
    flex-direction: column;
    gap: 100px;
  }
  .ul-menu{
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    li{
      list-style: circle;
    }
  }
  h5 {
    margin-bottom: 10px;
  }

  .images-wp {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    img {
      width: 45%;
    }
  }

  .two-cols {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;

    .col-1 {
      width: 70%;
      display: flex;
  flex-direction: column;
  gap: 23px;
    }

    .col-2 {
      width: 30%;

      img {
        width: 100%;
      }
    }
    .col-2-sm {
      width: 20%;

      img {
        width: 100%;
      }
    }
  }

  .menustage {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .col-menu {
      display: flex;
      flex-direction: column;
      gap: 24px;

      a {
        text-decoration: none;
      }
    }
  }

  span {
    font-weight: bolder;
    color: $violetclair;
  }

  .matiere {
    padding-top: 150px;

    h6 {
      margin-top: 0;
    }


  }
.pt-20{
  padding-top: 20px;
}

.grp-p{
  display: flex;
  flex-direction: column;
  gap: 23px;
}
}
.mcda{
  padding-top: 20px !important;
}