#root{
    background: radial-gradient(61.89% 22.18% at 50% 0%, #3B0268 0%, #000 100%);
}
.main{
    max-width: 1400px;
    margin: auto;
}

.entete-container {
    padding-top: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 10%;

    .asset-entete {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 300px;
        }

    }
}

.entete-container .entete {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $white;
    gap: 10px;
    padding-bottom: 10%;

    h1 {
        line-height: 125%;
        font-family: $font-2;

        span {
            color: $violetfonce;
        }
    }

    p {
        color: $gry;
        width: 95%;
    }

    a {
        // width: 40%;
        margin-top: 3%;
    }
}

.container-comp,
.container-projet {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
    margin-bottom: 10%;

    .entete-comp,
    .entete-projet {
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $white;
        gap: 10px;
        padding: 0 0 40px 0;

        h3 {
            font-family: $font-2;

            span {
                color: $violetfonce;
            }
        }

        p {
            color: $gry;
            width: 95%;
        }

        a {
            // width: 40%;
            margin-top: 3%;
        }

        .tag {
            border-radius: 24px;
            border: 1px solid white;
            padding: 10px 24px;
            font-size: small;
        }
    }
}

.content-comp {
    display: flex;
    flex-direction: column;
    gap: 100px;
    margin-bottom: 10%;

    .comp {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .img-comp {
            width: 90%;
            height: 200px;
            background-color: transparent;
            margin-bottom: 5%;
            border-radius: 26px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 26px;

            }
        }

        .info-comp {
            width: 90%;
            color: $white;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            gap: 10px;

            .logo-comp {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 10px;

                img {

                    height: 30px;
                }
            }

            .txt-comp {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                gap: 20px;
                text-align: left;

                p {
                    color: $gry;
                }

                a {
                    // max-width: 40%;
                    margin: 0;
                }
                .bouton{
                    padding: 0;
                }
            }

        }
    }
}

.content-projet {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .projet-link {
        width: 90%;
        margin-bottom: 10%;
    }

    .projet {
        display: flex;
        width: 100%;
        height: 100%;

        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        position: relative;
    }

    .imgprojet {
        align-self: stretch;
        border-radius: 8px;
        background: $violetfonce;
        height: 100%;
        width: 100%;
        // max-width: 400px;
        object-fit: cover;
        
    }

    .infosprojet.infos {
        visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #00000096;
    }

    .infosprojet.infos.visible {
        visibility: visible;
    }

    .infosprojet.visible {
        visibility: hidden;
    }

    .infosprojet {
        color: $white;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        background: rgb(0, 0, 0);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 70%);

        .txtprojet {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 17px 15px;
            flex-wrap: wrap;
            padding: 4%;

            .compprojet {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                gap: 8px;
                margin-top: 5%;
            }
        }

        .titreprojet {
            /* Corps */
            font-family: Montserrat;
            text-align: center;
            font-size: 95%;
        }

        .boutonprojet {
            width: 50%;
            margin: 0;
            font-size: medium;
        }
    }

}

.container-contact-bloc {
    width: 90%;
    text-align: center;
    border-radius: 37px;
    border: 1px solid rgba(112, 10, 192, 0.90);
    background: radial-gradient(118.22% 149.08% at 51.69% -15.81%, rgba(0, 0, 0, 0.00) 0%, rgba(112, 10, 192, 0.90) 9.59%, rgba(0, 0, 0, 0.00) 50.52%, rgba(112, 10, 192, 0.90) 81.24%, #8D00FC 85.96%, rgba(143, 0, 255, 0.00) 99.99%, rgba(0, 0, 0, 0.00) 100%, rgba(143, 0, 255, 0.00) 100%);
    margin: 10% auto;

    .content-contact-bloc {
        font-size: small;
        padding: 10% 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $white;
        gap: 25px;
    }
}


@media only screen and (min-width: 1200px) {
    
    .content-projet .infosprojet .boutonprojet{
        width: 70%;
    }
    .entete-container {
        padding-top: 10%;


        .asset-entete {
            height: 500px;

            img {
                width: 1200px;

            }
        }
    }

    .entete-container .entete {
        width: 80%;
        height: 100%;
        padding-bottom: 7%;

        p {
            color: $gry;
            width: 55%;
        }

    }

    .container-comp,
    .container-projet {


        .entete-comp,
        .entete-projet {

            p {
                color: $gry;
                width: 55%;
            }

        }
    }

    .content-comp {
        margin-bottom: 0;
        // margin: 0 5%;
        width: 90%;

        .comp {
            flex-direction: row;
            height: 350px;

            .img-comp {
                width: 45%;
                height: 100%;
                margin-bottom: 0;

            }

            .info-comp {
                width: 45%;

            }
        }
    }

    .content-projet {
        width: 90%;
        flex-direction: row;
        margin-bottom: 7%;

        .projet-link {
            width: 365px;
            height: 240px;
            margin-bottom: 0%;
margin: 0 2%;
        }

    }

    .container-contact-bloc {
        width: 90%;
        margin: 10% auto;

        .content-contact-bloc {
            font-size: large;

            padding: 10% 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: $white;
            gap: 25px;
        }
    }
}

@media only screen and (min-width: 992px) and (max-width : 1199px) {
    .content-projet .infosprojet .boutonprojet{
        width: 70%;
    }
    .content-projet {
        width: 80%;
        flex-direction: row;
        margin-bottom: 7%;
        flex-wrap: wrap;
        gap: 40px;

        .projet-link {
            width: 360px;
            margin-bottom: 0%;

        }

    }
   
    .entete-container {
        .asset-entete {
            img {
                width: 800px;
            }
        }
    }
    .comp.inverse {
        flex-direction: column-reverse;
    }

    .content-comp {
        margin-bottom: 0;
        // margin: 0 5%;
        width: 80%;

        .comp {
            flex-direction: row;
            height: 500px;
            // margin-bottom: 5%;

            .img-comp {
                width: 45%;
                height: 100%;
                margin-bottom: 0;

            }

            .info-comp {
                width: 45%;

            }
        }
    }

    .container-contact-bloc {
        width: 80%;
    }
}

@media only screen and (min-width: 768px) and (max-width : 991px) {
    .entete-container {
        .asset-entete {
            img {
                width: 600px;
            }
        }
    }
    .content-projet .infosprojet.infos.visible {
        visibility: hidden;
    }
    .content-projet .infosprojet.visible{
        visibility: visible;
    }
    .comp.inverse {
        flex-direction: column-reverse;
    }

    .content-comp {
        width: 90%;

        .comp {
            .img-comp {
                width: 90%;
                height: 350px;
                margin-bottom: 20px;

            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .entete-container {
        .asset-entete {
            img {
                width: 400px;
            }
        }
    }
    .content-projet .infosprojet .boutonprojet{
        width: 70%;
    }
    .comp.inverse {
        flex-direction: column-reverse;
    }

    .content-comp {
        width: 90%;

        .comp {
            .img-comp {
                width: 90%;
                height: 300px;
                margin-bottom: 20px;

            }
        }
    }
    .content-projet .infosprojet.infos.visible {
        visibility: hidden;
    }
    .content-projet .infosprojet.visible{
        visibility: visible;
    }
}

@media only screen and (max-width: 576px) {
    .entete-container {
        .asset-entete {
            img {
                width: 300px;
            }
        }
    }
    .content-projet .infosprojet.infos.visible {
        visibility: hidden;
    }
    .content-projet .infosprojet.visible{
        visibility: visible;
    }
    .content-projet .infosprojet .boutonprojet{
        width: 90%;
    }
    .comp.inverse {
        flex-direction: column-reverse;
    }
}
