.container-about{
max-width: 1400px;
margin: auto;
 .entete-about {
    padding-top: 150px;
    width: 100%;
    

    .content-about {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        padding-bottom: 7%;
        margin: auto;
        gap: 30px;

        .photo {
            width: 30%;
            display: none;
            img {
                max-width: 300px;
                border-radius: 50%;
                width: 100%;
            }
        }

        .entete {
            width: 90%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: $white;
            gap: 10px;

            h1 {
                line-height: 125%;
                font-family: $font-2;
                font-size: 1.7em;

                span {
                    color: $violetfonce;
                }
            }

            p {
                color: $gry;
                width: 95%;
            }

            a {
                margin-top: 3%;
            }
        }

    }
}

.about-comp {
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
padding-top: 40px;
padding-bottom: 50px;
margin: auto;

    .comp {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding-top: 30px;
        h4{
            padding-bottom: 15px;
        }
h5{
    font-size: medium;
    padding-bottom: 15px;
}
        .outilutilise {
            display: flex;
            flex-direction: row;
            align-items: center;
            // justify-content: center;
            color: $white;
            flex-wrap: wrap;
            padding-bottom: 3%;

            .outilcadre {
                padding: 1% 2%;
                margin-right: 2%;
                border-radius: 8px;
                font-size: medium;
                margin-bottom: 10px;
            }

            .violet4 {
                background: linear-gradient(145deg, #8000f7 0%, #39017e 100%);
            }

            .violet3 {
                background: linear-gradient(145deg, #6300f7 0%, #2b017e 100%);
            }

            .violet2 {
                background: linear-gradient(145deg, #4200f7 0%, #22017e 100%);
            }

            .violet {
                background: linear-gradient(145deg, #2d00f7 0%, #10017e 100%);
            }
        }
    }

    .lang-framework {
        width: 100%;
        padding-bottom: 3%;

    }

    .design {
        width: 100%;

    
    }

}

.about-parcours {
    color: $white;
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 30px 0;
text-align: left;
    .diplomes {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
gap: 40px;
padding-top: 30px;
        .diplome {
            display: flex;
            align-items: left;
            justify-content: left;
            flex-direction: column;
            width: 100%;

            h6 {
                padding-bottom: 5px;
            }

            span {
                padding-bottom: 7px;
            }

            p {
                color: rgb(175, 172, 172);
                // text-align: left;
            }
        }
    }
}
.about-experiences {
    color: $white;
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 30px 0;
text-align: left;
    .experiences {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
gap: 40px;
padding-top: 30px;
        .experience {
            display: flex;
            align-items: left;
            justify-content: left;
            flex-direction: column;
            width: 100%;

            h6 {
                padding-bottom: 5px;
            }

            span {
                padding-bottom: 7px;
            }

            p {
                color: rgb(175, 172, 172);
                // text-align: left;
            }
        }
    }
}
}
@media only screen and (min-width: 1200px) {
    .container-about .entete-about {
        padding-top: 100px;
        width: 100%;

        .content-about {
            gap: 100px;
            width: 70%;
            .photo{
                display: block;
            }
            .entete {
                width: 70%;
                h1 {
                    line-height: 125%;
                    font-family: $font-2;
                }
            }

        }
    }
    .container-about{

   
    .about-comp {
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    
        .comp {
            width: 90%;
            display: flex;
            // flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            >div {
                width: 100%;
                display: flex;
                align-items: self-start;
                justify-content: space-between;
            }
            h5 {
                padding-bottom: 20px;
                font-size: larger;
            }
            .outilutilise {
                display: flex;
                flex-direction: row;
                align-items: center;
                color: $white;
                flex-wrap: wrap;
                padding-bottom: 3%;

                .outilcadre {
                    padding: 1% 2%;
                    margin-right: 2%;
                    border-radius: 8px;
                    font-size: medium;
                    margin-bottom: 3%;
                }
            }
        }
        .lang-framework {
            width: 50%; 
        }
    
        .design {
            width: 50%
        }
    }

     .about-parcours, .about-experiences {
        color: $white;
        width: 90%;

        .diplomes {
            flex-direction: row;

            .diplome {
                justify-content: left;
                flex-direction: column;
                width: 45%;
            }
        }
    }
}
   }

@media only screen and (min-width: 992px) and (max-width : 1199px) {
    .about-comp{
        .comp{
            width: 90%;
            >div {
                width: 100%;
                display: flex;
                align-items: self-start;
                justify-content: space-between;

            }
            .outilutilise{
                justify-content: flex-start;
                padding-bottom: 0;
            }
            h5{
                font-size: large;
                text-align: left;
            } 
        }
    }
}

@media only screen and (min-width: 768px) and (max-width : 991px) {
    .about-comp .comp{
        h5{
            font-size: large;
            
        } 
        >div {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: self-start;
            justify-content: space-between;
        }
    }
}

@media only screen and (max-width: 767px) {}

@media only screen and (max-width: 576px) {
    .container-about .entete-about .content-about .photo {
        display: none;
    }
}