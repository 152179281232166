.bg-sae {
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(59, 2, 104, 1) 50%, rgba(0, 0, 0, 1) 100%);
}

.sae {
    .menuSAE {
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .col-menu {
            display: flex;
            flex-direction: column;
            gap: 24px;

            a {
                text-decoration: none;
            }
        }
    }

    span {
        font-weight: bolder;
        color: $violetclair;
    }

    .matiere {
        padding-top: 150px;

        h6 {
            margin-top: 0;
        }

        .img-grp {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 24px;

            img {
                width: 45%;
            }
        }

        img {
            width: 100%;
        }



    }

}