.slide-in-bottom {
  opacity: 0;
  transform: translateY(100px);
  /* Déplacez l'élément vers le bas */
  transition: opacity 1s, transform 1s;
}

.slide-in-bottom.active {
  opacity: 1;
  transform: translateY(0);
  /* Annulez le déplacement vers le bas */
}

.slide-left {
  opacity: 0;
  transform: translateX(-20px);
  /* Déplacez l'élément vers la gauche */
  transition: opacity 1s, transform 1s;
}

.slide-left.active {
  opacity: 1;
  transform: translateX(0);
  /* Annulez le déplacement vers la gauche */
}

.slide-right {
  opacity: 0;
  transform: translateX(20px);
  /* Déplacez l'élément vers la droite */
  transition: opacity 1s, transform 1s;
}

.slide-right.active {
  opacity: 1;
  transform: translateX(0);
  /* Annulez le déplacement vers la droite */
}


.fade-in {
  animation: fade-in 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {

    opacity: 1;
  }
}

.fade-in-accueil-titre {
  animation: fade-in-accueil-titre 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes fade-in-accueil-titre {
  0% {
    transform: translateY(100px);

    opacity: 0;
  }

  100% {
    transform: translateY(0px);

    opacity: 1;
  }
}

.fade-in-accueil-texte {
  animation: fade-in-accueil-texte 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes fade-in-accueil-texte {
  0% {
    transform: translateY(100px);

    opacity: 0;
  }

  20% {
    transform: translateY(100px);

    opacity: 0;
  }

  100% {
    transform: translateY(0px);

    opacity: 1;
  }
}

.fade-in-accueil-btn {
  animation: fade-in-accueil-btn 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes fade-in-accueil-btn {
  0% {
    transform: translateY(100px);

    opacity: 0;
  }

  30% {
    transform: translateY(100px);

    opacity: 0;
  }

  100% {
    transform: translateY(0px);

    opacity: 1;
  }
}

.fade-in-accueil-asset {
  animation: fade-in-accueil-asset 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes fade-in-accueil-asset {
  0% {
    transform: translateY(100px);

    opacity: 0;
  }

  30% {
    transform: translateY(100px);

    opacity: 0;
  }

  100% {
    transform: translateY(0px);

    opacity: 1;
  }
}

.fade-in-up {
  animation: fade-in-up 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes fade-in-up {
  0% {
    transform: translateY(100px);

    opacity: 0;
  }

  100% {
    transform: translateY(0px);

    opacity: 1;
  }
}

.fade-in-up2 {
  animation: fade-in-up2 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes fade-in-up2 {
  0% {
    transform: translateY(100px);

    opacity: 0;
  }

  20% {
    transform: translateY(100px);

    opacity: 0;
  }

  100% {
    transform: translateY(0px);

    opacity: 1;
  }
}