.bouton {
    border-radius: 20px;
    // border: 1px solid #a0a0a0;
    // height: 30px;
    // width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    text-decoration: none;
    padding: 8px 20px;
    min-width: 10%;
    margin: 0 2%;
    cursor: pointer;

    img {
        height: 15px;
        margin: 0 0 0 10px;

    }

    a {
        color: #FFF;
    }

}

.boutonviolet {
    border: 3px solid var(--second, #0C003F);
    color: #0C003F;
}

.boutonfondviolet {
    padding: 10px 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    border-radius: 24px;
    background: linear-gradient(145deg, #8800f7 0%, #46017e 100%);
}

.bouton:hover {
    // background: linear-gradient(145deg, #8800f7 0%, #46017e 100%);

}

.moving-image {
    transition: transform 0.5s ease; 
  }
  .nomoving-image {
    transition: transform 0.5s ease; 
  }
 .moving-image {
    transform: translateX(10px); 
  }
  .nomoving-image {
    transform: translateX(0px); 
  }